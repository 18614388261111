import * as React from 'react'

interface IProps {
  hitpoint: any
  scale: number
  onClickHitpoint: () => void
}

interface IState {
  left: number
  top: number
  width: number
  height: number
  step_is_menu?: boolean
}

class Hitpoint extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)

    const {
      left,
      top,
      width,
      height,
      step_is_menu
    } = this.props.hitpoint

    this.state = {
      left,
      top,
      width,
      height,
      step_is_menu
    }
  }

  public componentWillReceiveProps (nextProps: Readonly<IProps>) {
    const {
      left,
      top,
      width,
      height,
      step_is_menu
    } = nextProps.hitpoint

    this.setState({
      left,
      top,
      width,
      height,
      step_is_menu
    })
  }

  public render () {
    const scale = this.props.scale || 1

    let {
      left,
      top,
      width,
      height,
      step_is_menu
    } = this.state
    const cursor = step_is_menu ? "pointer" : "auto"

    console.log(step_is_menu)

    left = left * scale
    top = top * scale
    width = width * scale
    height = height * scale

    return (
      <div
        className='Hitpoint'
        style={{position: 'absolute', cursor, left, top, width, height}}
        onClick={this.props.onClickHitpoint} />
    )
  }
}

export default Hitpoint
