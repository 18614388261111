import * as React from 'react'

import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import uuid from 'uuid/v4'

import { IHitpointerProps } from '../Hitpointer'

export interface IProps {
  hitpoints: any[]
  backgroundImage: string
  LinkingComponent?: React.ComponentClass<any>
  onSaveChanges: (changes: any) => void
}

export interface IState {
  hitpoints: {
    active: null | string,
    dirty: boolean
    itemsDeleted: any[]
    items: object
  }
}

const INITIAL_STATE: IState = {
  hitpoints: {
    active: null,
    dirty: false,
    itemsDeleted: [],
    items: {}
  }
}

const withHitpointerState = (WrappedComponent: React.ComponentType<IHitpointerProps>) => {
  return class WithHitpointerState extends React.Component<IProps, IState> {
    constructor (props: IProps) {
      super(props)
      this.state = this.buildState()
    }

    componentDidUpdate (prevProps: IProps) {
      if (!isEqual(prevProps.hitpoints, this.props.hitpoints)) {
        this.setState(this.buildState())
      }
    }

    public buildState = (): IState => {
      return {
        ...INITIAL_STATE,
        hitpoints: {
          ...INITIAL_STATE.hitpoints,
          items: {
            ...this.buildHitpoints(this.props.hitpoints)
          }
        }
      }
    }

    public render () {
      return (
        <WrappedComponent
          {...this.props}
          activeHitpoint={this.state.hitpoints.active}
          hitpoints={this.getHitpoints()}
          dirtyState={this.state.hitpoints.dirty}
          saveTemporaryHitpoint={this.saveTemporaryHitpoint}
          getHitpoint={this.getHitpoint}
          updateHitpoint={this.updateHitpoint}
          deleteHitpoint={this.deleteHitpoint}
          setActiveHitpoint={this.setActiveHitpoint}
          resetActiveHitpoint={this.resetActiveHitpoint}
          resetAllChanges={this.resetAllChanges}
          saveAllChanges={this.saveAllChanges} />
      )
    }

    public buildHitpoints = (hitpoints: any[]): any => {
      if (!hitpoints) return {}

      const hitpointsById = {}

      hitpoints.map((hitpoint) => {
        hitpointsById[hitpoint.id] = hitpoint
      })

      return hitpointsById
    }

    public getHitpoints = () => {
      return Object.values(this.state.hitpoints.items)
    }

    public getHitpoint = (id) => {
      if (!id) return
      return this.state.hitpoints.items[id]
    }

    public saveTemporaryHitpoint = (hitpoint, active) => {
      const hitpointId = `temp-${uuid()}`
      const activeHitpointId = active ? hitpointId : this.state.hitpoints.active

      this.setState({
        ...this.state,
        hitpoints: {
          ...this.state.hitpoints,
          dirty: true,
          active: activeHitpointId,
          items: {
            ...this.state.hitpoints.items,
            [hitpointId]: {
              id: hitpointId,
              ...hitpoint,
              dirty: true
            }
          }
        }
      })
    }
    
    public updateHitpoint = (hitpoint) => {
      this.setState({
        ...this.state,
        hitpoints: {
          ...this.state.hitpoints,
          dirty: true,
          items: {
            ...this.state.hitpoints.items,
            [hitpoint.id]: {
              ...this.state.hitpoints.items[hitpoint.id],
              ...hitpoint,
              dirty: true
            }
          }
        }
      })
    }

    public deleteHitpoint = (id: string) => {
      const deleteIds: string[] = []

      if (!`${id}`.startsWith('temp')) {
        deleteIds.push(id)
      }

      this.setState({
        ...this.state,
        hitpoints: {
          ...this.state.hitpoints,
          dirty: true,
          itemsDeleted: [
            ...this.state.hitpoints.itemsDeleted,
            ...deleteIds
          ],
          items: pickBy(this.state.hitpoints.items, (hitpoint) => hitpoint.id !== id)
        }
      })
    }

    public setActiveHitpoint = (id) => {
      this.setState({
        ...this.state,
        hitpoints: {
          ...this.state.hitpoints,
          active: id
        }
      })
    }

    public resetActiveHitpoint = () => {
      this.setState({
        ...this.state,
        hitpoints: {
          ...this.state.hitpoints,
          active: null
        }
      })
    }

    public resetAllChanges = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      this.setState(this.buildState())
    }

    public sanitizeHitpoints = (hitpointsById: any) => {
      return Object.values(hitpointsById).map((hitpoint: any) => { delete hitpoint.dirty; return hitpoint })
    }

    public getNewHitpoints = () => {
      return this.sanitizeHitpoints(
        pickBy(this.state.hitpoints.items, (hitpoint) => hitpoint.id && `${hitpoint.id}`.startsWith('temp'))
      ).map((hitpoint: any) => { delete hitpoint.id; return hitpoint })
    }

    public getUpdatedHitpoints = () => {
      return this.sanitizeHitpoints(
        pickBy(this.state.hitpoints.items, (hitpoint) => (hitpoint.id && !`${hitpoint.id}`.startsWith('temp')) && hitpoint.dirty)
      )
    }

    public saveAllChanges = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()

      this.props.onSaveChanges({
        new: this.getNewHitpoints(),
        updated: this.getUpdatedHitpoints(),
        deleted: this.state.hitpoints.itemsDeleted
      })
    }
  }
}


export default withHitpointerState
