import * as React from 'react'

import Hitpoint from './Hitpoint'

interface IProps {
  scale: number
  hitpoints: any[]
  onClickHitpoint: (hitpoint: any) => void
}

class Hitpoints extends React.Component<IProps> {
  public render () {
    return (
      <div className='hitpoints-container'
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <div className='hitpoints' style={{ position: 'relative' }}>
          {this._renderHitpoints()}
        </div>
      </div>
    )
  }

  private _renderHitpoints = () => {
    const {
      scale,
      hitpoints,
      onClickHitpoint
    } = this.props

    return hitpoints.map((hitpoint) => {
      return (
        <Hitpoint
          key={hitpoint.id}
          hitpoint={hitpoint}
          scale={scale}
          onClickHitpoint={() => onClickHitpoint(hitpoint)} />
      )
    })
  }
}

export default Hitpoints
