import * as React from 'react'

import HitpointTooltip from './HitpointTooltip'

interface IProps {
  hitpoint: any
  scale: number
  className?: string
  activeHitpoint?: null | string
  modifyingActiveHitpoint?: boolean
  LinkingComponent?: React.ComponentClass<any>
  setActiveHitpoint?: (id: string) => void
  updateHitpoint: (hitpoint: any) => void
}

interface IState {
  left: number
  top: number
  width: number
  height: number
  mouseDown: boolean
  mouseOrigin: {
    x: number,
    y: number
  },
  pullHandles: string[]
}

class Hitpoint extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)

    const {
      left,
      top,
      width,
      height
    } = this.props.hitpoint

    this.state = {
      left,
      top,
      width,
      height,
      mouseDown: false,
      mouseOrigin: {
        x: 0,
        y: 0
      },
      pullHandles: [
        'top',
        'left',
        'right',
        'bottom'
      ]
    }
  }

  public componentWillReceiveProps (nextProps: Readonly<IProps>) {
    const {
      left,
      top,
      width,
      height
    } = nextProps.hitpoint

    this.setState({
      left,
      top,
      width,
      height
    })
  }

  public render () {
    const scale = this.props.scale || 1

    let {
      left,
      top,
      width,
      height
    } = this.state

    left = left * scale
    top = top * scale
    width = width * scale
    height = height * scale

    const isActive = this._isActive()
    const appendClasses = isActive ? 'active' : ''

    return (
      <div className={`Hitpoint ${this.props.className || ''} ${appendClasses}`}
        style={{position: 'absolute', left, top, width, height}}
        onMouseDown={this._onMouseDown}>

        <HitpointTooltip
          show={this._showTooltip()}
          hitpoint={this.props.hitpoint}
          LinkingComponent={this.props.LinkingComponent}
          updateHitpoint={this.props.updateHitpoint} />

        {this._renderPullHandles()}
      </div>
    )
  }

  private _isActive = () => {
    const {
      hitpoint,
      activeHitpoint
    } = this.props

    if (activeHitpoint && hitpoint) {
      return activeHitpoint === hitpoint.id
    }

    return false
  }

  private _onMouseDown = () => {
    const {
      setActiveHitpoint,
      hitpoint
    } = this.props

    if (setActiveHitpoint) {
      setActiveHitpoint(hitpoint.id)
    }
  }

  private _showTooltip = () => {
    return this._isActive() && !this.props.modifyingActiveHitpoint
  }

  private _renderPullHandles = () => {
    if (!this._isActive()) return null

    return this.state.pullHandles.map((position) => {
      return <div key={position} className={`PullHandle ${position}`} />
    })
  }
}

export default Hitpoint
