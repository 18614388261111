import * as React from 'react'

interface IProps {
  show: boolean
  hitpoint: any
  LinkingComponent?: React.ComponentClass<any>
  updateHitpoint: (hitpoint: any) => void
}

interface IState {
  tooltipContentHeight: number
}

class HitpointTooltip extends React.Component<IProps, IState> {
  private tooltipContentEl = React.createRef<HTMLDivElement>()

  constructor (props: IProps) {
    super(props)

    this.state = {
      tooltipContentHeight: 0
    }
  }

  public componentDidMount () {
    if (this.props.show) {
      this.setTooltipContentHeight()
    }
  }

  public componentDidUpdate (prevProps: IProps) {
    if (this.props.show && (prevProps.show !== this.props.show)) {
      this.setTooltipContentHeight()
    }
  }

  public setTooltipContentHeight = () => {
    const tooltipContent = this.tooltipContentEl.current

    if (tooltipContent) {
      this.setState({
        tooltipContentHeight: tooltipContent.clientHeight
      })
    }
  }

  public render () {
    const { show, LinkingComponent } = this.props

    if (!show || !LinkingComponent) return null

    return (
      <div
        className='hitpoint-tooltip'
        style={{ top: this._hitpointTooltipTop() }}
        onMouseDown={this._stopPropagation}
        onClick={this._stopPropagation}>

        <div className='hitpoint-tooltip-content' ref={this.tooltipContentEl}>
          <LinkingComponent hitpoint={this.props.hitpoint} updateHitpointLink={this.updateHitpointLink} />
        </div>
      </div>
    )
  }

  private updateHitpointLink = (hitpointUpdates) => {
    const { hitpoint } = this.props

    this.props.updateHitpoint({
      id: hitpoint.id,
      ...hitpointUpdates
    })
  }

  private _hitpointTooltipTop = () => {
    return (this.state.tooltipContentHeight + 20) * -1
  }

  private _stopPropagation = (event) => {
    event.stopPropagation()
  }
}

export default HitpointTooltip
