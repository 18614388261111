import * as React from 'react'

import Hitpoint from './Hitpoint'
import Hitpoints from './Hitpoints'

import withHitpointerState from './data/withHitpointerState'

export interface IHitpointerProps {
  backgroundImage: string
  dirtyState: boolean
  activeHitpoint: null | string
  hitpoints: any[]
  LinkingComponent?: React.ComponentClass<any>
  saveTemporaryHitpoint: (hitpoint: any, active: boolean) => void
  getHitpoint: (id: string | null) => any
  updateHitpoint: (hitpoint: any) => void
  deleteHitpoint: (id: string) => void
  setActiveHitpoint: (id: string) => void
  resetActiveHitpoint: () => void
  resetAllChanges: (event: React.MouseEvent<HTMLElement>) => void
  saveAllChanges: (event: React.MouseEvent<HTMLElement>) => void
}

interface ICoordinates {
  x: number
  y: number
}

interface IState {
  imageLoaded: boolean
  originalWidth: number
  currentWidth: number
  currentHeight: number
  modifyingHitpoint: boolean
  modifyLastPosition: ICoordinates
  drawStartPosition: ICoordinates
  drawCurrentPosition: ICoordinates
  pullingHandle: {
    left: boolean
    right: boolean
    top: boolean
    bottom: boolean
  }
  drawHitpoint: ICoordinates & {
    drawing: boolean
    width: number
    height: number
  }
}

class Hitpointer extends React.Component<IHitpointerProps, IState> {
  private image: any = null
  private timer: any = null

  public state = {
    imageLoaded: false,
    originalWidth: 0,
    currentWidth: 0,
    currentHeight: 0,
    modifyingHitpoint: false,
    modifyLastPosition: {
      x: 0,
      y: 0
    },
    pullingHandle: {
      left: false,
      right: false,
      top: false,
      bottom: false
    },
    drawStartPosition: {
      x: 0,
      y: 0
    },
    drawCurrentPosition: {
      x: 0,
      y: 0
    },
    drawHitpoint: {
      drawing: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }
  }

  public componentDidMount () {
    window.addEventListener('keyup', this._windowOnKeyup)
    window.addEventListener('keydown', this._windowStopDefaultBackspace)
    window.addEventListener('keypress', this._windowStopDefaultBackspace)

    this.timer = setInterval(this._imageResizeObserver, 100)
  }

  public componentWillUnmount () {
    window.removeEventListener('keyup', this._windowOnKeyup)
    window.removeEventListener('keydown', this._windowStopDefaultBackspace)
    window.removeEventListener('keypress', this._windowStopDefaultBackspace)

    clearInterval(this.timer)
    this.timer = null
  }

  public render () {
    const {
      backgroundImage
    } = this.props

    return (
      <div className='mr-hitpointer--Container'>
        <div className='Hitpointer'
          style={{ position: 'relative' }}
          onMouseDown={this._onMouseDown}
          onMouseMove={this._onMouseMove}
          onMouseUp={this._onMouseUp}>

          <img
            src={backgroundImage}
            ref={this._onImgElementRef}
            onLoad={this._imgOnLoad} />

          <Hitpoints
            hitpoints={this.props.hitpoints}
            scale={this._calculateScale()}
            activeHitpoint={this.props.activeHitpoint}
            LinkingComponent={this.props.LinkingComponent}
            setActiveHitpoint={this.props.setActiveHitpoint}
            updateHitpoint={this.props.updateHitpoint}
            modifyingActiveHitpoint={this.state.modifyingHitpoint} />

          {this._drawingHitpoint()}
        </div>

        {this._renderControls()}
      </div>
    )
  }

  private _drawingHitpoint = () => {
    if (!this.state.drawHitpoint.drawing) return null

    return (
      <Hitpoint
        className='active'
        hitpoint={{
          left: this.state.drawHitpoint.x,
          top: this.state.drawHitpoint.y,
          width: this.state.drawHitpoint.width,
          height: this.state.drawHitpoint.height
        }}
        updateHitpoint={this.props.updateHitpoint}
        scale={1} />
    )
  }

  private _renderControls = () => {
    if (!this.props.dirtyState) return null

    return (
      <div className='hitpointer-controls'>
        <button className='button is-danger' onClick={this.props.resetAllChanges}>Cancel</button>
        <button className='button is-primary' onClick={this.props.saveAllChanges}>Save hitpoints</button>
      </div>
    )
  }

  private _onMouseDown = (event) => {
    const currentPosition = this._relativeXY(event)
    const clientPosition = this._clientPosition(event)
    const targetClasses = event.target.classList

    if (targetClasses.contains('Hitpoint') || targetClasses.contains('PullHandle')) {
      return this.setState({
        modifyingHitpoint: true,
        modifyLastPosition: clientPosition,
        pullingHandle: {
          left: targetClasses.contains('left'),
          right: targetClasses.contains('right'),
          top: targetClasses.contains('top'),
          bottom: targetClasses.contains('bottom')
        }
      })
    }

    this.props.resetActiveHitpoint()

    this.setState({
      drawStartPosition: currentPosition,
      drawCurrentPosition: currentPosition,
      drawHitpoint: {
        drawing: true,
        ...currentPosition,
        width: 0,
        height: 0
      }
    })
  }

  private _onMouseMove = (event) => {
    if (this.state.modifyingHitpoint) {
      const scale = this._calculateScale()
      const hitpoint = this.props.getHitpoint(this.props.activeHitpoint)

      if (!hitpoint) return

      const position = this._clientPosition(event)
      const delta = {
        x: (position.x - this.state.modifyLastPosition.x) / scale,
        y: (position.y - this.state.modifyLastPosition.y) / scale
      }

      this.setState({ modifyLastPosition: position })

      if (this.state.pullingHandle.left ||
          this.state.pullingHandle.right ||
          this.state.pullingHandle.top ||
          this.state.pullingHandle.bottom) {
        if (this.state.pullingHandle.left) {
          this.props.updateHitpoint({
            ...hitpoint,
            left: hitpoint.left + delta.x,
            width: Math.max(5, hitpoint.width - delta.x)
          })
        }

        if (this.state.pullingHandle.right) {
          this.props.updateHitpoint({
            ...hitpoint,
            width: Math.max(5, hitpoint.width + delta.x)
          })
        }

        if (this.state.pullingHandle.top) {
          this.props.updateHitpoint({
            ...hitpoint,
            top: hitpoint.top + delta.y,
            height: Math.max(5, hitpoint.height - delta.y)
          })
        }

        if (this.state.pullingHandle.bottom) {
          this.props.updateHitpoint({
            ...hitpoint,
            height: Math.max(5, hitpoint.height + delta.y)
          })
        }
      } else {
        this.props.updateHitpoint({
          ...hitpoint,
          left: this._positionInBounds('x', hitpoint.left + delta.x),
          top: this._positionInBounds('y', hitpoint.top + delta.y)
        })
      }
    }

    if (this.state.drawHitpoint.drawing) {
      const relativePosition = this._relativeXY(event)

      this.setState({
        drawCurrentPosition: relativePosition,
        drawHitpoint: {
          ...this.state.drawHitpoint,
          ...this._hitpointDimensions(relativePosition)
        }
      })
    }
  }

  private _onMouseUp = () => {
    const { drawHitpoint } = this.state
    const scale = this._calculateScale()

    this.setState({
      modifyingHitpoint: false,
      pullingHandle: {
        left: false,
        right: false,
        top: false,
        bottom: false
      }
    })

    if (!drawHitpoint.drawing) return

    this.setState({
      drawHitpoint: {
        ...drawHitpoint,
        drawing: false
      }
    })

    if (drawHitpoint.width > 5 && drawHitpoint.height > 5) {
      this.props.saveTemporaryHitpoint({
        left: drawHitpoint.x / scale,
        top: drawHitpoint.y / scale,
        width: drawHitpoint.width / scale,
        height: drawHitpoint.height / scale
      }, true)
    }
  }

  private _relativeXY = (event) => {
    const targetIsHitpoint = event.target.classList.contains('Hitpoint')
    const bodyRect = document.body.getBoundingClientRect()

    // If the event target is a hitpoint then the cursor is moving
    // quickly and accelerating above the Hitpoint element. In this
    // case we need to target the event target parent element to
    // determine the proper XY coordinates.
    const elementRect = targetIsHitpoint
      ? event.target.parentElement.getBoundingClientRect()
      : event.target.getBoundingClientRect()

    const offsetY = elementRect.top - bodyRect.top
    const offsetX = elementRect.left - bodyRect.left

    return {
      x: event.pageX - offsetX,
      y: event.pageY - offsetY
    }
  }

  private _positionInBounds = (axis, coordinate) => {
    const {
      currentWidth,
      currentHeight
    } = this.state
    const hitpoint = this.props.getHitpoint(this.props.activeHitpoint)
    const scale = this._calculateScale()

    switch (axis) {
      case 'x':
        // left bound
        coordinate = Math.max(0, coordinate)

        // right bound
        const rightBound = (currentWidth / scale) - hitpoint.width
        coordinate = Math.min(rightBound, coordinate)

        return coordinate
      case 'y':
        // top bound
        coordinate = Math.max(0, coordinate)

        // bottom bound
        const bottomBound = (currentHeight / scale) - hitpoint.height
        coordinate = Math.min(bottomBound, coordinate)

        return coordinate
    }
  }

  private _clientPosition = (event) => {
    return {
      x: event.clientX,
      y: event.clientY
    }
  }

  private _hitpointDimensions = (currentPosition) => {
    const {
      drawStartPosition
    } = this.state

    const width = Math.max(0, currentPosition.x - drawStartPosition.x)
    const height = Math.max(0, currentPosition.y - drawStartPosition.y)

    return {
      width,
      height
    }
  }

  private _calculateScale = () => {
    const {
      originalWidth,
      currentWidth
    } = this.state

    if (originalWidth === 0 || currentWidth === 0) return 1

    return currentWidth / originalWidth
  }

  private _onImgElementRef = (img) => {
    this.image = img
  }

  private _imgOnLoad = () => {
    if (this.image) {
      const { naturalWidth, width, height } = this.image

      this.setState({
        imageLoaded: true,
        originalWidth: naturalWidth,
        currentWidth: width,
        currentHeight: height
      })
    }
  }

  private _deleteActiveHitpoint = () => {
    const { activeHitpoint } = this.props

    if (activeHitpoint) {
      this.props.deleteHitpoint(activeHitpoint)
      this.props.resetActiveHitpoint()
    }
  }

  private _windowOnKeyup = (event: KeyboardEvent) => {
    const BACKSPACE = 8
    const DELETE = 46
    const ESCAPE = 27

    switch (event.keyCode) {
      case BACKSPACE:
      case DELETE:
        return this._deleteActiveHitpoint()
      case ESCAPE:
        return this.props.resetActiveHitpoint()
    }
  }

  private _windowStopDefaultBackspace = (event: any) => {
    if (event.keyCode == 8) {
      const elements = 'HTML, BODY, TABLE, TBODY, TR, TD, DIV'
      const el: HTMLElement | null = (event.srcElement as HTMLElement) || (event.target as HTMLElement)

      if (el) {
        const regex = new RegExp(el.tagName.toUpperCase())
        if (el.contentEditable != 'true') {
          if (regex.test(elements)) {
            event.preventDefault ? event.preventDefault() : event.returnValue = false
          }
        }
      }
    }
  }

  private _imageResizeObserver = () => {
    const {
      imageLoaded,
      currentWidth
    } = this.state

    if (imageLoaded && this.image) {
      if (currentWidth !== this.image.width) {
        this.setState({
          currentWidth: this.image.width,
          currentHeight: this.image.height
        })
      }
    }
  }
}

const HitpointerWithState = withHitpointerState(Hitpointer)
export default HitpointerWithState
