import * as React from 'react'

import Hitpoints from './Hitpoints'

interface IProps {
  backgroundImage: string
  hitpoints: any[]
  onClickHitpoint: (hitpoint: any) => void
}

interface IState {
  imageLoaded: boolean
  originalWidth: number
  currentWidth: number
  currentHeight: number
}

class Viewer extends React.Component<IProps, IState> {
  private image: any = null
  private timer: any = null

  public state = {
    imageLoaded: false,
    originalWidth: 0,
    currentWidth: 0,
    currentHeight: 0
  }

  public componentDidMount () {
    this.timer = setInterval(this._imageResizeObserver, 100)
  }

  public componentWillUnmount () {
    clearInterval(this.timer)
    this.timer = null
  }

  public render () {
    const { 
      backgroundImage,
      hitpoints,
      onClickHitpoint
     } = this.props

    return (
      <div className='mr-hitpointer--container'>
        <div className='Viewer' style={{ position: 'relative' }} >
          <img src={backgroundImage} ref={this._onImgElementRef} onLoad={this._imgOnLoad} />

          <Hitpoints
            hitpoints={hitpoints}
            scale={this._calculateScale()}
            onClickHitpoint={onClickHitpoint} />
        </div>
      </div>
    )
  }

  private _calculateScale = () => {
    const {
      originalWidth,
      currentWidth
    } = this.state

    if (originalWidth === 0 || currentWidth === 0) return 1

    return currentWidth / originalWidth
  }

  private _onImgElementRef = (img) => {
    this.image = img
  }

  private _imgOnLoad = () => {
    if (this.image) {
      const { naturalWidth, width, height } = this.image

      this.setState({
        imageLoaded: true,
        originalWidth: naturalWidth,
        currentWidth: width,
        currentHeight: height
      })
    }
  }

  private _imageResizeObserver = () => {
    const {
      imageLoaded,
      currentWidth
    } = this.state

    if (imageLoaded && this.image) {
      if (currentWidth !== this.image.width) {
        this.setState({
          currentWidth: this.image.width,
          currentHeight: this.image.height
        })
      }
    }
  }
}

export default Viewer
