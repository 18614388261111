 import React from 'react'
import axios from 'axios'

import reactMountElements from 'react-mount-elements'

import { Viewer } from '../mreach-mr-hitpointer'

import 'promise-polyfill/src/polyfill'

class HitpointViewer extends React.Component {
  state = {
    hitpoints: []
  }

  async componentDidMount () {
    await this.fetchHitpoints()
  }

  fetchHitpoints = async () => {
    const stepId = this.props.stepId
    const { data: hitpoints } = await axios.get(`/api/v1/steps/${stepId}/hitpoints`)

    this.setState({ hitpoints })
  }

  render () {
    return (
      <Viewer
        hitpoints={this.state.hitpoints}
        backgroundImage={this.props.backgroundImage}
        onClickHitpoint={this._hitpointClick} />
    )
  }

  _hitpointClick = (hitpoint) => {
    const {
      link_scenario_slug,
      link_step_id,
      step_is_menu
    } = hitpoint


    if (link_scenario_slug && link_step_id) {
      const path = `/scenario/${link_scenario_slug}/step/${link_step_id}`

      const payload = { detail:
          { linkName: `infosight:${link_scenario_slug}` }
      }

      if (step_is_menu) {
        console.debug(`send ANALYTICS.LINK with linkName=infosight:${link_scenario_slug}`)
        window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", payload))
      }

      Turbolinks ? Turbolinks.visit(path) : window.location = path
    }
  }
}

reactMountElements({
  'mr-hitpointer--viewer': HitpointViewer
})
